<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="6">
            <b-form-group label="Main Category" label-for="blogCat">
              <v-select
                placeholder="Select Category"
                v-model="row.mac_type"
                :options="Config.mr.mrCatType"
                :reduce="v => v.value"
                label="text"
              />
              <VValidate 
                name="Main Category" 
                v-model="row.mac_type" 
                :rules="mrValidation.mac_type" 
              />
            </b-form-group>
          </b-col>  
          <div class="w-100"></div>
          <b-col lg="4">
            <b-form-group label-for="catName">
              <label>Category Name (ID)<span class="text-danger mr5">*</span></label>
              <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" v-model="row.mac_category_id" placeholder="e.g Teknologi" />
              <VValidate 
                name="Category Name (ID)" 
                v-model="row.mac_category_id" 
                :rules="{...mrValidation.mac_category_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group label-for="catNameEn">
              <label>Category Name (EN)<span class="text-danger mr5">*</span></label>
              <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" v-model="row.mac_category_en" placeholder="e.g Technology" />
              <VValidate 
                name="Category Name (EN)" 
                v-model="row.mac_category_en" 
                :rules="{...mrValidation.mac_category_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group label-for="catStatus">
              <label>Status <span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row[$parent.statusKey]"
              />
              <VValidate 
                name="Status" 
                v-model="row[$parent.statusKey]" 
                :rules="mrValidation[$parent.statusKey]" 
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Save Changes</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  data(){
    return {
      mrCatType:[]
    }
  },
}
</script>